import { handleErrorResponse } from "../lib/utils";
import { Job, JobComment, JobDocuments } from "../types/Job";
import { JobLabel } from "../types/Labels";
import { User } from "../types/user";
import { JobStatus } from "../types/workspace";
import {
  del,
  get,
  postDataWithFormData,
  put,
  putDataWithFormData,
  post,
} from "./api";

export const getJobsQuery = async (data: {
  workspaceId?: number;
  filters: {
    filter?: string;
    assignedUsers?: string;
    hasReminder?: number;
    statusId?: number;
    page?: number;
  };
}): Promise<{
  jobs?: Job[];
  filteredJobsCount?: number;
  allJobsCount?: number;
  isLastPage?: number;
  errorCode?: number;
  error?: string;
}> => {
  let url = `/job/workspace/${data.workspaceId}?page=${
    data?.filters?.page ? data.filters.page : 1
  }`;

  if (data?.filters?.filter) {
    url = url + `&filter=${encodeURIComponent(data.filters.filter)}`;
  }

  if (data?.filters?.assignedUsers) {
    url =
      url + `&assignedUsers=${encodeURIComponent(data.filters.assignedUsers)}`;
  }

  if (data?.filters?.hasReminder) {
    url = url + `&hasReminder=${encodeURIComponent(data.filters.hasReminder)}`;
  }

  if (data?.filters?.statusId) {
    url = url + `&statusId=${encodeURIComponent(data.filters.statusId)}`;
  }

  try {
    const res = await get(url);
    if (res.data)
      return {
        jobs: res.data.jobs,
        filteredJobsCount: res.data.filteredJobsCount,
        isLastPage: res.data.isLastPage,
        allJobsCount: res.data.allJobsCount,
      };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const getUsersToAssignQuery = async (data: {
  workspaceId?: number;
  filter?: string;
}): Promise<{
  users?: User[];
  errorCode?: number;
  error?: string;
}> => {
  let url = `/job/workspace/${data.workspaceId}/users${
    data.filter ? `?filter=${data.filter}` : ""
  }`;
  try {
    const res = await get(url);
    if (res.data)
      return {
        users: res.data.users,
      };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const deleteJobMutate = async (
  jobId: number
): Promise<{
  isJobDeleted?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/job/${jobId}`;

  try {
    const res = await del(url);
    if (res.data) return { isJobDeleted: res.data.isJobDeleted };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const getJobByIdMutate = async (
  jobId?: number
): Promise<{
  job?: Job;
  jobStatuses?: JobStatus[];
  errorCode?: number;
  error?: string;
}> => {
  const url = `/job/${jobId}`;

  try {
    const res = await get(url);
    if (res.data)
      return { job: res.data.job, jobStatuses: res.data.jobStatuses };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const createJobMutate = async (
  formData: FormData
): Promise<{ job?: Job; errorCode?: number; error?: string }> => {
  const url = `/job`;

  try {
    const res = await postDataWithFormData(url, formData);
    if (res.data) return { job: res.data.job };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const updateJobLabelsMutate = async (data: {
  jobLabels: JobLabel[];
  workspaceId: number;
  jobId: number;
}): Promise<{ jobLabels?: JobLabel; errorCode?: number; error?: string }> => {
  const url = `/job/${data.jobId}/jobLabels`;

  try {
    const res = await put(url, data);
    if (res.data) return { jobLabels: res.data.jobLabels };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const updateJobStatusMutate = async (data: {
  statusId: number;
  workspaceId: number;
  jobId: number;
}): Promise<{
  statusUpdated?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/job/${data.jobId}/status`;

  try {
    const res = await put(url, data);
    if (res.data) return { statusUpdated: res.data.statusUpdated };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const updateAssignedToMutate = async (data: {
  assignedUsers: string;
  workspaceId: number;
  jobId: number;
}): Promise<{
  assignedUsersUpdated?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/job/${data.jobId}/assignedUsers`;

  try {
    const res = await put(url, data);
    if (res.data)
      return { assignedUsersUpdated: res.data.assignedUsersUpdated };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const addDocumentToJobMutate = async (data: {
  jobId: number;
  files: File[];
  workspaceId: number;
}): Promise<{
  documents?: JobDocuments[];
  errorCode?: number;
  error?: string;
}> => {
  const url = `/job/${data.jobId}/document`;
  const formData = new FormData();

  data.files.forEach((file, index) => {
    formData.append("files", file);
  });

  try {
    const res = await putDataWithFormData(url, formData);
    if (res.data) return { documents: res.data.documents };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const removeJobDocumentMutate = async (data: {
  jobId: number;
  documentId: number;
  workspaceId: number;
}): Promise<{
  success?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/job/${data.jobId}/document/${data.documentId}`;

  try {
    const res = await del(url);
    if (res.data) return { success: res.data.success };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const updateJobReminderMutate = async (data: {
  jobId: number;
  workspaceId: number;
  hasReminder: boolean;
  reminderText: string | null;
}): Promise<{ success?: boolean; errorCode?: number; error?: string }> => {
  const url = `/job/${data.jobId}/reminder`;

  try {
    const res = await put(url, data);
    if (res.data) return { success: res.data.success };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const addJobCommentMutate = async (data: {
  jobId: number;
  workspaceId: number;
  text: string;
}): Promise<{
  comment?: JobComment;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/job/${data.jobId}/comment`;

  try {
    const res = await post(url, data);
    if (res.data) return { comment: res.data.comment };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const updateJobCommentMutate = async (data: {
  jobId: number;
  commentId: number;
  text: string;
}): Promise<{
  comment?: JobComment;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/job/${data.jobId}/comment/${data.commentId}`;

  try {
    const res = await put(url, { text: data.text });
    if (res.data) return { comment: res.data.comment };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const removeJobCommentMutate = async (data: {
  jobId: number;
  commentId: number;
}): Promise<{
  success?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/job/${data.jobId}/comment/${data.commentId}`;

  try {
    const res = await del(url);
    if (res.data) return { success: res.data.success };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};
