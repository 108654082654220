import { Flex } from "@chakra-ui/react";
import BaseLayout from "../layouts/BaseLayout";
import { useTranslation } from "react-i18next";
import { useCheckIsMobile } from "../lib/utils";
import { Heading } from "../components/Atoms/Heading/Heading";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { Button } from "../components/Atoms/Button/Button";
import { FaPlus } from "react-icons/fa";
import { WorkspaceUserRoles } from "../types/workspace";
import { useCreateJob } from "../hooks/jobs";
import { object, string } from "yup";
import { Option } from "../components/Molecules/Select/Select";
import { JobDocuments } from "../types/Job";
import "react-photo-view/dist/react-photo-view.css";
import Inputs from "../components/Organisms/Job/Inputs";
import Statuses from "../components/Organisms/Job/Statuses";
import AssignedTo from "../components/Organisms/Job/AssignedTo";
import Documents from "../components/Organisms/Job/Documents";
import Reminder from "../components/Organisms/Job/Reminder";

export let jobSchema = object({
  text: string().required({
    field: "text",
    translationKey: "validation.requiredFiled",
  }),
});

const NewJobPage = () => {
  const { t } = useTranslation();
  const isMobile = useCheckIsMobile();

  const { currentWorkspace } = useContext(AppContext);
  const { createJob, isPendingCreateJob } = useCreateJob();
  const [jobLabels, setJobLabels] = useState<
    { labelId: number; text: string }[]
  >([]);
  const [errors, setErrors] = useState({ text: "" });

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileUrls, setFileUrls] = useState<JobDocuments[]>([]);
  const [jobReminder, setJobReminder] = useState<string | undefined>(undefined);

  const [selectedUserOption, setSelectedUserOption] = useState<
    Option | Option[] | undefined
  >(undefined);

  const [jobStatusOptions, setJobStatusOptions] = useState<Option[]>([
    {
      value: "",
      label: t("job.statusNotAssigned"),
    },
  ]);
  const [selectedOption, setSelectedOption] = useState<Option>(
    jobStatusOptions[0]
  );

  const isAllowedToAddJob =
    currentWorkspace &&
    (currentWorkspace.role === WorkspaceUserRoles.ADMIN ||
      currentWorkspace.role === WorkspaceUserRoles.OWNER ||
      (currentWorkspace.role === WorkspaceUserRoles.BASIC_USER &&
        currentWorkspace.privileges?.canAddJob));

  const handleCreateJob = async () => {
    if (!currentWorkspace?.id || !jobLabels.length) return;
    try {
      await jobSchema.validate(jobLabels[0], {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("name", jobLabels[0].text);
      formData.append("workspaceId", currentWorkspace?.id.toString());
      const labels = JSON.stringify(jobLabels);
      formData.set("jobLabels", labels.substring(1, labels.length - 1));
      if (selectedOption.value)
        formData.set("statusId", selectedOption.value?.toString());

      if (selectedUserOption && Array.isArray(selectedUserOption)) {
        const userIds = selectedUserOption.map((user) => user.value).join(",");
        formData.append("selectedUsers", userIds);
      }

      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });

      if (jobReminder) {
        formData.append("hasReminder", "true");
        formData.append("reminderText", jobReminder);
      }

      await createJob(formData);
    } catch (error: any) {
      let errors: any = {};
      if (error.errors.length) {
        error.errors.forEach(
          (err: { field: string; translationKey: string }) => {
            errors[err.field as keyof typeof errors] = t(err.translationKey);
          }
        );
        setErrors(errors);
      }
    }
  };

  useEffect(() => {
    if (currentWorkspace?.labels) {
      const labels = currentWorkspace.labels.map((label) => {
        return {
          labelId: label.id || 0,
          text: "",
        };
      });
      setJobLabels(labels);
    }
    if (currentWorkspace?.jobStatuses) {
      const statusOptions: Option[] = currentWorkspace?.jobStatuses?.map(
        (status) =>
          ({
            label: status.name,
            value: status.id,
          } as Option)
      );
      if (statusOptions) {
        setJobStatusOptions(statusOptions);
        setSelectedOption(statusOptions[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace]);

  const updateLabelText = (value: string, index: number, labelId?: number) => {
    if (labelId) {
      const labels = jobLabels.map((label) => {
        if (label.labelId === labelId) {
          return {
            ...label,
            text: value,
          };
        }
        return label;
      });
      setJobLabels(labels);
      if (index === 0 && !!value) setErrors({ text: "" });
    }
  };

  const getInputValue = (labelId?: number) => {
    if (labelId) {
      const label = jobLabels.find((label) => label.labelId === labelId);
      return label?.text || "";
    }
    return "";
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newFiles: File[] = Array.from(files);

      const newFileUrls: JobDocuments[] = newFiles
        .filter((file) => file?.type?.includes("image/"))
        .map((file) => {
          return {
            filePath: URL.createObjectURL(file),
            name: file.name,
            type: file.type,
          };
        });

      setSelectedFiles([...selectedFiles, ...newFiles]);
      setFileUrls([...fileUrls, ...newFileUrls]);
    }
  };

  const handleRemoveFile = (index: number) => {
    if (isPendingCreateJob) return;
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);

    const newFileUrls: JobDocuments[] = updatedSelectedFiles
      .filter((file) => file.type.includes("image/"))
      .map((file) => {
        return {
          filePath: URL.createObjectURL(file),
          name: file.name,
          type: file.type,
        };
      });

    setFileUrls(newFileUrls);
    setSelectedFiles(updatedSelectedFiles);
  };

  const handleChangeStatusOption = (option: Option) => {
    setSelectedOption(option);
  };

  const handleChangeAssignedUsers = (
    selectedUserOption: Option | Option[] | undefined
  ) => {
    setSelectedUserOption(selectedUserOption);
  };

  const labels =
    currentWorkspace?.labels && !!currentWorkspace.labels.length
      ? currentWorkspace.labels
      : [];

  return (
    <BaseLayout>
      <Flex
        sx={{
          p: isMobile ? "24px" : "48px",
          flexDir: "column",
          width: "100%",
          fontSize: "32px",
          alignItems: "center",
        }}
      >
        <Heading
          sx={{
            color: "black.title",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            gap: "12px",
            mb: "32px",
          }}
        >
          {t("job.new")}
        </Heading>
        <Inputs
          updateLabelText={updateLabelText}
          getInputValue={getInputValue}
          errors={errors}
          labels={labels}
        />
        <Statuses
          jobStatusOptions={jobStatusOptions}
          selectedOption={selectedOption}
          onOptionChange={handleChangeStatusOption}
        />
        <AssignedTo
          selectedUserOption={selectedUserOption}
          onChangeUsers={handleChangeAssignedUsers}
        />
        <Documents
          isDisabled={isPendingCreateJob}
          handleFileInputChange={handleFileInputChange}
          handleRemoveFile={handleRemoveFile}
          selectedFiles={selectedFiles}
          fileUrls={fileUrls}
        />
        <Reminder jobReminder={jobReminder} setJobReminder={setJobReminder} />
        {isAllowedToAddJob && (
          <Flex sx={{ height: "44px", width: "full" }}>
            <Button
              size="full"
              leftIcon={<FaPlus color="white" />}
              isDisabled={!isAllowedToAddJob || isPendingCreateJob}
              sx={{ mt: "32px" }}
              onClick={() => handleCreateJob()}
            >
              {t("job.add")}
            </Button>
          </Flex>
        )}
      </Flex>
    </BaseLayout>
  );
};

export default NewJobPage;
