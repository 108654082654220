import { Flex } from "@chakra-ui/react";
import BaseLayout from "../layouts/BaseLayout";
import { Heading } from "../components/Atoms/Heading/Heading";
import { useTranslation } from "react-i18next";
import { useCheckIsMobile } from "../lib/utils";

const NotificationsPage = () => {
  const { t } = useTranslation();
  const isMobile = useCheckIsMobile();
  return (
    <BaseLayout>
      <Flex
        sx={{
          p: isMobile ? "24px" : "48px",
          flexDir: "column",
          width: "100%",
          fontSize: "32px",
          alignItems: "center",
        }}
      >
        <Heading
          sx={{
            color: "black.title",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            gap: "12px",
            mb: "32px",
          }}
        >
          {t("notifications.title")}
        </Heading>
      </Flex>
    </BaseLayout>
  );
};

export default NotificationsPage;
