import { useTranslation } from "react-i18next";
import SectionHeading from "../../Molecules/Common/SectionHeading";
import SectionItem from "../../Molecules/Common/SectionItem";
import Select, { Option } from "../../Molecules/Select/Select";

interface StatusesProps {
  jobStatusOptions: Option[];
  selectedOption: Option;
  onOptionChange: (option: Option) => void;
}

const Statuses = ({
  jobStatusOptions,
  selectedOption,
  onOptionChange,
}: StatusesProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionHeading
        title={t("job.statusesTitle")}
        subtitle={t("job.statusesSubtitle")}
        sx={{ my: "20px" }}
      />
      <SectionItem
        label={""}
        sx={{
          flexDir: ["column", "column", "row"],
          gap: "20px",
        }}
        labelColor="gray.darker"
      >
        <Select
          isMulti={false}
          isSearchable={false}
          name="jobStatuses"
          theme="default"
          options={jobStatusOptions}
          withImages={false}
          defaultOption={selectedOption}
          onChange={(option) => {
            if (option) {
              onOptionChange(option);
            }
          }}
        />
      </SectionItem>
    </>
  );
};

export default Statuses;
