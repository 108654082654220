import { handleErrorResponse } from "../lib/utils";
import { post, put } from "./api";

export const registerDevice = async (data: {
  fcmToken: string;
  deviceType: "web";
}): Promise<{ success?: boolean; errorCode?: number; error?: string }> => {
  const url = `/device/register`;

  try {
    const res = await post(url, data);
    if (res.data) return { success: res.data.success };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const updateDeviceActivity = async (data: {
  fcmToken: string;
  isActive: boolean;
}): Promise<{ success?: boolean; errorCode?: number; error?: string }> => {
  const url = `/device/activity`;
  try {
    const res = await put(url, data);
    if (res.data) return { success: res.data.success };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};
