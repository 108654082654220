import { useTranslation } from "react-i18next";
import { useRef } from "react";
import SectionHeading from "../../Molecules/Common/SectionHeading";
import SectionItem from "../../Molecules/Common/SectionItem";
import { Button } from "../../Atoms/Button/Button";
import { Box, Flex, List, ListIcon, ListItem, Text } from "@chakra-ui/react";
import { MdOutlineClose } from "react-icons/md";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { JobDocuments } from "../../../types/Job";
import { useCheckIsMobile } from "../../../lib/utils";

interface DocumentsProps {
  isDisabled: boolean;
  handleRemoveFile: (index: number) => void;
  handleFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFiles: File[];
  fileUrls: JobDocuments[];
  existingJobDocuments?: JobDocuments[];
}

const Documents = ({
  isDisabled,
  handleRemoveFile,
  handleFileInputChange,
  selectedFiles,
  fileUrls,
  existingJobDocuments,
}: DocumentsProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const isMobile = useCheckIsMobile();

  const handleAddFile = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current.click();
    }
  };
  return (
    <>
      <SectionHeading
        title={t("job.documents")}
        subtitle={t("job.documentsSubtitle")}
        sx={{ my: "40px" }}
      />
      <SectionItem
        label={
          <>
            <Button variant="quaternary" onClick={handleAddFile}>
              {t("job.newDocument")}
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileInputChange}
              multiple
              disabled={isDisabled}
            />
          </>
        }
        sx={{
          flexDir: ["column", "column", "row"],
          gap: "20px",
          w: "100%",
        }}
        labelColor="gray.darker"
      >
        <Flex
          flexDir={["column", "column", "column", "row"]}
          w={"100%"}
          alignItems={"start"}
        >
          <List gap={3} w={isMobile ? "100%" : "50%"}>
            {selectedFiles.map((file, index) => (
              <ListItem
                my={2}
                display={"flex"}
                flexDir={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                key={file.lastModified}
                w={"100%"}
              >
                <a
                  href={URL.createObjectURL(file)}
                  rel="noreferrer"
                  download={file.name}
                  style={{ flexGrow: 1, minWidth: 0 }}
                >
                  <Text
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "gray.darker",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                      display: "block",
                    }}
                  >
                    {file.name}
                  </Text>
                </a>
                <ListIcon
                  as={MdOutlineClose}
                  color="red"
                  cursor={"pointer"}
                  onClick={() => handleRemoveFile(index)}
                  height={"20px"}
                  width={"20px"}
                  ml={"8px"}
                />
              </ListItem>
            ))}
            {existingJobDocuments?.map((file, index) => (
              <ListItem
                my={2}
                display={"flex"}
                flexDir={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                key={file.id}
                w={"100%"}
              >
                <a
                  href={file.filePath}
                  rel="noreferrer"
                  download={file.name}
                  style={{ flexGrow: 1, minWidth: 0 }}
                >
                  <Text
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "gray.darker",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                      display: "block",
                    }}
                  >
                    {file.name}
                  </Text>
                </a>
                <ListIcon
                  as={MdOutlineClose}
                  color="red"
                  cursor={"pointer"}
                  onClick={() => handleRemoveFile(index)}
                  height={"20px"}
                  width={"20px"}
                  ml={"8px"}
                />
              </ListItem>
            ))}
          </List>
          <Flex
            flexDir={"row"}
            justifyContent={"start"}
            alignItems={"start"}
            flexWrap={"wrap"}
            flexGrow={1}
          >
            <PhotoProvider>
              {fileUrls.map((image, index) => (
                <div style={{ position: "relative" }} key={index}>
                  <PhotoView src={image.filePath}>
                    <Box
                      bg="teal"
                      height="70px"
                      width={"70px"}
                      backgroundImage={`url(${image.filePath})`}
                      backgroundSize="cover"
                      backgroundPosition="center"
                      cursor={"pointer"}
                      m={"8px"}
                      borderRadius={"6px"}
                    ></Box>
                  </PhotoView>
                </div>
              ))}
            </PhotoProvider>
          </Flex>
        </Flex>
      </SectionItem>
    </>
  );
};

export default Documents;
