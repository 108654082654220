import { useContext, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  LoginUserMutate,
  checkUserEmailMutate,
  currentUserQuery,
  getUsersByEmailQuery,
  registerUserMutate,
  resendVerificationEmailToUserMutate,
  resetPasswordMutate,
  sendForgotPasswordEmailMutate,
  updateUserDataMutate,
  updateUserPasswordMutate,
  verifyUserEmailMutate,
} from "../services/user";
import { AlertMessageStatus } from "../components/Molecules/AlertMessage/AlertMessage";
import { useTranslation } from "react-i18next";
import { errorCodeToTranslationMapper } from "../mappers/ErrorCodeMapper";
import { AppContext } from "../context/AppContext";
import { ErrorCodes } from "../types/ErrorCodes";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { getFCMToken } from "../firebase/firebase";
import { registerDevice, updateDeviceActivity } from "../services/notification";

export const useRegisterUser = () => {
  const { t } = useTranslation();
  const { setAlertMessage, setUser, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);

  const { mutateAsync: registerUser, isPending: isPendingRegistration } =
    useMutation({
      mutationFn: registerUserMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.user) {
          setUser(data.user);
        }
        if (data.errorCode) {
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            false
          );
        }
      },
    });

  return { registerUser, isPendingRegistration };
};

export const useCheckUserEmail = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);

  const { mutateAsync: checkUserEmail, isPending: isPendingEmailValidation } =
    useMutation({
      mutationFn: checkUserEmailMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.errorCode) {
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            false
          );
        }
      },
    });

  return {
    checkUserEmail,
    isPendingEmailValidation,
  };
};

export const useResendVerificationEmailToUser = () => {
  const { t } = useTranslation();
  const { setAlertMessage, user } = useContext(AppContext);

  const {
    mutateAsync: resendVerificationEmailToUser,
    isPending: isPendingResendEmail,
  } = useMutation({
    mutationFn: resendVerificationEmailToUserMutate,
    onSuccess: (data) => {
      if (data.isEmailSent) {
        setAlertMessage(
          {
            title: t("register.emailSentSuccessfully", { email: user?.email }),
            variant: AlertMessageStatus.SUCCESS,
          },
          false
        );
      }
      if (data.errorCode) {
        setAlertMessage(
          {
            title: t(errorCodeToTranslationMapper[data.errorCode]),
            description: data.error
              ? data.error
              : t("errors.ERROR_CODE", { code: data.errorCode }),
            variant: AlertMessageStatus.ERROR,
          },
          false
        );
      }
    },
  });

  return {
    resendVerificationEmailToUser,
    isPendingResendEmail,
  };
};

export const useVerifyEmail = () => {
  const { t } = useTranslation();
  const { setAlertMessage } = useContext(AppContext);

  const {
    mutateAsync: verifyUserEmail,
    isPending: isPendingEmailVerification,
  } = useMutation({
    mutationFn: verifyUserEmailMutate,
    onSuccess: (data) => {
      if (data.errorCode) {
        setAlertMessage(
          {
            title: t(errorCodeToTranslationMapper[data.errorCode]),
            description: data.error
              ? data.error
              : t("errors.ERROR_CODE", { code: data.errorCode }),
            variant: AlertMessageStatus.ERROR,
          },
          false
        );
      }
    },
  });

  return {
    verifyUserEmail,
    isPendingEmailVerification,
  };
};

export const useLoginUser = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);

  const { mutateAsync: loginUser, isPending: isPendingLoginUser } = useMutation(
    {
      mutationFn: LoginUserMutate,
      onSuccess: async (data) => {
        if (showAlertMessage) closeAlertMessage();
        // Request notification permission after successful login
        if (data.user && !data.errorCode) {
          try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
              const token = await getFCMToken();
              if (token) {
                await registerDevice({
                  fcmToken: token,
                  deviceType: "web",
                });
              }
            }
          } catch (err) {
            console.error("Failed to get notification permission:", err);
          }
        }
        if (data.errorCode) {
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            false
          );
        }
      },
    }
  );

  return {
    loginUser,
    isPendingLoginUser,
  };
};

export const useSendForgotPasswordEmail = () => {
  const { t } = useTranslation();
  const { setAlertMessage } = useContext(AppContext);

  const {
    mutateAsync: sendForgotPasswordEmail,
    isPending: isPendingSendingEmail,
  } = useMutation({
    mutationFn: sendForgotPasswordEmailMutate,
    onSuccess: (data) => {
      if (data.errorCode) {
        setAlertMessage(
          {
            title: t(errorCodeToTranslationMapper[data.errorCode]),
            description: data.error
              ? data.error
              : t("errors.ERROR_CODE", { code: data.errorCode }),
            variant: AlertMessageStatus.ERROR,
          },
          false
        );
      }
    },
  });

  return {
    sendForgotPasswordEmail,
    isPendingSendingEmail,
  };
};

export const useResetPassword = () => {
  const { t } = useTranslation();
  const { setAlertMessage } = useContext(AppContext);
  const navigate = useNavigate();

  const { mutateAsync: resetPassword, isPending: isPendingResetPassword } =
    useMutation<
      {
        isPasswordUpdated?: boolean;
        errorCode?: number;
        error?: string;
      },
      Error,
      { password: string; token: string }
    >({
      mutationFn: resetPasswordMutate,
      onSuccess: (data) => {
        if (data.errorCode) {
          if (
            data.errorCode === ErrorCodes.UNAUTHORIZED ||
            data.errorCode === ErrorCodes.SESSION_EXPIRED
          ) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
            setAlertMessage(
              {
                title: t("errors.RESET_PASSWORD_LINK_INVALID"),
                variant: AlertMessageStatus.ERROR,
              },
              true
            );
            return;
          } else {
            setAlertMessage(
              {
                title: t(errorCodeToTranslationMapper[data.errorCode]),
                description: data.error
                  ? data.error
                  : t("errors.ERROR_CODE", { code: data.errorCode }),
                variant: AlertMessageStatus.ERROR,
              },
              false
            );
          }
        }
      },
    });

  return {
    resetPassword,
    isPendingResetPassword,
  };
};

export const useGetCurrentUser = () => {
  const navigate = useNavigate();
  const { setAlertMessage } = useContext(AppContext);
  const { t } = useTranslation();

  const accessToken = Cookies.get("acc_tkn");
  const refreshToken = Cookies.get("ref_tkn");

  const { data, isFetching } = useQuery({
    queryKey: ["currentUser"],
    queryFn: currentUserQuery,
    enabled: !!accessToken && !!refreshToken,
  });

  // Handle visibility change for active user
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (data?.user) {
        try {
          const permission = Notification.permission;
          if (permission === "granted") {
            const token = await getFCMToken();
            if (token) {
              await updateDeviceActivity({
                fcmToken: token,
                isActive: !document.hidden,
              });
            }
          }
        } catch (error) {
          console.debug("Could not update device activity:", error);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [data?.user]);

  // Handle error cases
  useEffect(() => {
    if (data?.errorCode) {
      Object.keys(Cookies.get()).forEach(function (cookieName) {
        Cookies.remove(cookieName);
      });
      navigate("/login");
      setAlertMessage(
        {
          title: t(errorCodeToTranslationMapper[data.errorCode]),
          description: data.error
            ? data.error
            : t("errors.ERROR_CODE", { code: data.errorCode }),
          variant: AlertMessageStatus.ERROR,
        },
        false
      );
    }
  }, [data, navigate, setAlertMessage, t]);

  return {
    currentUser: data?.user,
    workspaces: data?.workspaces,
    isFetchingCurrentUser: isFetching,
  };
};

export const useUpdateUserData = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage, setUser } =
    useContext(AppContext);
  const navigate = useNavigate();

  const { mutateAsync: updateUserData, isPending: isPendingUpdateUserData } =
    useMutation({
      mutationFn: updateUserDataMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
        if (data.user && data.user.id) {
          setUser(data.user);
          setAlertMessage(
            {
              title: t("success.userUpdated"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
        }
      },
    });

  return { updateUserData, isPendingUpdateUserData };
};

export const useUpdateUserPassword = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();

  const {
    mutateAsync: updateUserPassword,
    isPending: isPendingUpdateUserPassword,
  } = useMutation({
    mutationFn: updateUserPasswordMutate,
    onSuccess: (data) => {
      if (showAlertMessage) closeAlertMessage();
      if (data.errorCode) {
        if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
          Object.keys(Cookies.get()).forEach(function (cookieName) {
            Cookies.remove(cookieName);
          });
          navigate("/login");
        }
        if (data.errorCode === ErrorCodes.WRONG_CREDENTIALS) {
          setAlertMessage(
            {
              title: t("errors.WRONG_CURRENT_PASSWORD"),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        } else {
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      }
      if (data.isUserPasswordUpdated) {
        setAlertMessage(
          {
            title: t("success.userPasswordUpdated"),
            variant: AlertMessageStatus.SUCCESS,
          },
          true
        );
      }
    },
  });

  return { updateUserPassword, isPendingUpdateUserPassword };
};

export const useGetUsersByEmail = ({
  workspaceId,
  email,
}: {
  workspaceId?: number;
  email: string;
}) => {
  const { setAlertMessage } = useContext(AppContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const accessToken = Cookies.get("acc_tkn");
  const refreshToken = Cookies.get("ref_tkn");

  const { data, isFetching } = useQuery({
    queryKey: ["usersByEmail", workspaceId, email],
    queryFn: async () =>
      await getUsersByEmailQuery({
        email,
        workspaceId,
      }),
    enabled:
      !!accessToken && !!refreshToken && !!workspaceId && !!(email.length > 2),
  });

  useEffect(() => {
    if (data?.errorCode) {
      setAlertMessage(
        {
          title: t(errorCodeToTranslationMapper[data.errorCode]),
          description: data.error
            ? data.error
            : t("errors.ERROR_CODE", { code: data.errorCode }),
          variant: AlertMessageStatus.ERROR,
        },
        false
      );
    }
  }, [data, setAlertMessage, t]);

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["usersByEmail"] });
  };

  return {
    users: data?.users,
    isFetchingUserData: isFetching,
    invalidateQueries,
  };
};
