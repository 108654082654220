import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Textarea,
} from "@chakra-ui/react";

import SectionHeading from "../../Molecules/Common/SectionHeading";
import SectionItem from "../../Molecules/Common/SectionItem";
import { useTranslation } from "react-i18next";
import { JobComment } from "../../../types/Job";
import { Flex, Text } from "@chakra-ui/react";
import TooltipAvatar from "../../Molecules/TooltipAvatar/TooltipAvatar";
import { formatDistanceToNow } from "date-fns";
import { hr, enUS } from "date-fns/locale";
import { Button } from "../../Atoms/Button/Button";
import { IoMdSend } from "react-icons/io";
import { useContext, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { FiEdit2, FiMoreVertical } from "react-icons/fi";
import { AppContext } from "../../../context/AppContext";

interface CommentsProps {
  comments: JobComment[];
  onAddComment: (text: string) => void;
  onUpdateComment?: (commentId: number, text: string) => Promise<void>;
  onRemoveComment?: (commentId: number) => Promise<void>;
  isDisabled?: boolean;
}

const Comments = ({
  comments,
  onAddComment,
  isDisabled = false,
  onUpdateComment,
  onRemoveComment,
}: CommentsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionHeading
        title={t("job.comments")}
        subtitle={t("job.commentsSubtitle")}
        sx={{ my: "40px" }}
      />
      <SectionItem
        label={""}
        sx={{
          flexDir: ["column", "column", "row"],
          gap: "20px",
        }}
        labelColor="gray.darker"
      >
        <Flex
          flexDir="column"
          gap="10px"
          w="100%"
          border="1px solid"
          borderColor="gray.bottom"
          borderRadius="10px"
          p="10px"
        >
          {comments.map((comment) => (
            <Comment
              key={comment.id}
              comment={comment}
              onUpdateComment={onUpdateComment}
              onRemoveComment={onRemoveComment}
            />
          ))}
          <NewComment onAddComment={onAddComment} isDisabled={isDisabled} />
        </Flex>
      </SectionItem>
    </>
  );
};

const NewComment = ({
  onAddComment,
  isDisabled = false,
}: {
  onAddComment: (text: string) => void;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [commentText, setCommentText] = useState("");
  return (
    <Flex flexDir="row" gap="10px" w="100%" align="center">
      <Textarea
        placeholder={t("job.newCommentPlaceholder")}
        border="1px solid"
        borderColor="gray.bottom"
        borderRadius="10px"
        p="10px"
        resize="none"
        onChange={(e) => setCommentText(e.target.value)}
        value={commentText}
      />
      <Button
        size="sm"
        onClick={() => {
          onAddComment(commentText);
          setCommentText("");
        }}
        isDisabled={isDisabled}
      >
        <Icon as={IoMdSend} />
      </Button>
    </Flex>
  );
};

interface CommentProps {
  comment: JobComment;
  onUpdateComment?: (commentId: number, text: string) => Promise<void>;
  onRemoveComment?: (commentId: number) => Promise<void>;
}
const Comment = ({
  comment,
  onUpdateComment,
  onRemoveComment,
}: CommentProps) => {
  const { i18n, t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(comment.text);
  const { user } = useContext(AppContext);

  const isCommentOwner = user?.id === comment.user?.id;

  const handleUpdate = async () => {
    if (!onUpdateComment || editedText.trim() === comment.text) return;
    await onUpdateComment(comment.id, editedText.trim());
    setIsEditing(false);
  };

  const handleRemove = async () => {
    if (!onRemoveComment) return;
    await onRemoveComment(comment.id);
  };

  const getLocale = () => {
    switch (i18n.language) {
      case "hr":
        return hr;
      default:
        return enUS;
    }
  };

  const formatDate = (date: string) => {
    return formatDistanceToNow(new Date(date), {
      addSuffix: true,
      locale: getLocale(),
    });
  };

  return (
    <Flex
      flexDir="column"
      gap="10px"
      w="100%"
      my="10px"
      borderBottom="1px solid"
      borderColor="gray.bottom"
      pb="10px"
    >
      <Flex flexDir="row" gap="10px" w="100%">
        <Flex align="center" w="100%" flexDir="row">
          <TooltipAvatar
            w="45px"
            h="45px"
            src={comment.user?.filePath || undefined}
            name={
              !comment.user?.filePath
                ? comment.user?.firstName + " " + comment.user?.lastName
                : undefined
            }
            color="white"
          />
          <Flex
            flexDir="column"
            mx={3}
            sx={{
              fontSize: "14px",
              flexGrow: 1,
              overflow: "hidden",
              width: "100%",
              color: "gray.darker",
            }}
          >
            <Text
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {comment.user?.firstName}
            </Text>
            <Text
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {comment.user?.lastName}
            </Text>
          </Flex>
        </Flex>
        <Flex gap="2" align="center">
          <Text
            sx={{
              fontSize: "12px",
              overflow: "hidden",
              width: "100%",
              color: "gray.darker",
            }}
          >
            {formatDate(comment.updatedAt)}
          </Text>
          {isCommentOwner && (
            <Menu placement="bottom-end">
              <MenuButton
                as={IconButton}
                icon={<FiMoreVertical />}
                variant="ghost"
                size="sm"
                aria-label="Comment options"
              />
              <MenuList border="1px solid" borderColor="gray.bottom">
                <MenuItem
                  icon={<FiEdit2 />}
                  onClick={() => setIsEditing(true)}
                  sx={{ color: "black.title", fontSize: "14px" }}
                >
                  {t("job.editComment")}
                </MenuItem>
                <MenuItem
                  icon={<FiTrash2 />}
                  onClick={handleRemove}
                  color="red.500"
                  sx={{ fontSize: "14px" }}
                >
                  {t("job.removeComment")}
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
      {isEditing ? (
        <Flex flexDir="column" gap="2" pl="55px">
          <Textarea
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            resize="none"
          />
          <Flex gap="2" justifyContent="flex-end">
            <Button
              size="sm"
              //variant="ghost"
              onClick={() => {
                setIsEditing(false);
                setEditedText(comment.text);
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              size="sm"
              onClick={handleUpdate}
              isDisabled={
                !editedText.trim() || editedText.trim() === comment.text
              }
            >
              {t("common.update")}
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Text sx={{ fontSize: "14px", color: "black.title", pl: "55px" }}>
          {comment.text}
        </Text>
      )}
    </Flex>
  );
};

export default Comments;
