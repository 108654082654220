import { useTranslation } from "react-i18next";
import { SetStateAction } from "react";
import SectionHeading from "../../Molecules/Common/SectionHeading";
import SectionItem from "../../Molecules/Common/SectionItem";
import { Flex, Textarea } from "@chakra-ui/react";

interface ReminderProps {
  jobReminder?: string;
  setJobReminder: (value: SetStateAction<string | undefined>) => void;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  isPending?: boolean;
  isSomethingUpdated?: boolean;
  disabled?: boolean;
  variant?: "add" | "edit";
}

const Reminder = ({
  jobReminder,
  setJobReminder,
  onPrimaryClick,
  onSecondaryClick,
  isPending,
  isSomethingUpdated = false,
  disabled = false,
  variant = "add",
}: ReminderProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionHeading
        title={t("job.reminder")}
        subtitle={t("job.reminderSubtitle")}
        sx={{ my: "40px" }}
        primaryButtonLabel={variant === "edit" ? t("common.update") : undefined}
        secondaryButtonLabel={
          variant === "edit" ? t("common.cancel") : undefined
        }
        disabled={!isSomethingUpdated || disabled}
        onClickPrimary={onPrimaryClick}
        onClickSecondary={onSecondaryClick}
      />
      <SectionItem label="">
        <Flex
          sx={{
            width: "full",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <Textarea
            resize={"none"}
            _placeholder={{ color: "gray.light" }}
            _hover={{ borderColor: "gray.dark" }}
            focusBorderColor="gray.light"
            sx={{
              color: "black.darkText",
              mb: "20px",
              boxShadow: "base",
              w: "100%",
              h: "100px",
            }}
            placeholder={t("job.reminderPlaceholder")}
            onChange={(e) => setJobReminder(e.target.value)}
            value={jobReminder}
            isDisabled={isPending}
          />
        </Flex>
      </SectionItem>
    </>
  );
};

export default Reminder;
