import { useEffect, useContext } from "react";
import { getFCMToken } from "../../../firebase/firebase";
import { registerDevice } from "../../../services/notification";
import { AppContext } from "../../../context/AppContext";

export const NotificationPermission = () => {
  const { user } = useContext(AppContext);

  useEffect(() => {
    const requestPermission = async () => {
      // Only proceed if user is logged in
      if (!user) return;

      try {
        if (!("Notification" in window)) {
          return;
        }

        const permission = await Notification.requestPermission();

        if (permission === "granted") {
          const token = await getFCMToken();
          if (token) {
            await registerDevice({
              fcmToken: token,
              deviceType: "web",
            });
          }
        }
      } catch (err) {
        console.error("Failed to get permission:", err);
      }
    };

    requestPermission();
  }, [user]); // Run when user changes

  return null;
};
