import { Flex } from "@chakra-ui/react";
import { CgProfile } from "react-icons/cg";
import { MdNotifications, MdOutlineSettings } from "react-icons/md";
import { PiUsersThreeBold } from "react-icons/pi";
import { TbBriefcase2 } from "react-icons/tb";

import NavItem from "./NavItem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useContext } from "react";
import { useCheckIsMobile } from "../../../lib/utils";
import { WorkspaceUserRoles } from "../../../types/workspace";

const SidebarItems = () => {
  const { t } = useTranslation();
  const isMobile = useCheckIsMobile();
  const { setIsSidebarOpen, currentWorkspace } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <Flex sx={{ flexGrow: 1, flexDir: "column", width: "full" }}>
      {currentWorkspace && (
        <NavItem
          label={t("job.jobs")}
          isActive={window.location.pathname.includes("/job")}
          Icon={TbBriefcase2}
          onClick={() => {
            if (isMobile) setIsSidebarOpen(false);
            navigate("/job");
          }}
        />
      )}
      <NavItem
        label={t("profile.myProfile")}
        isActive={window.location.pathname.includes("/profile")}
        Icon={CgProfile}
        onClick={() => {
          if (isMobile) setIsSidebarOpen(false);
          navigate("/profile");
        }}
      />
      {currentWorkspace &&
        currentWorkspace?.role &&
        (currentWorkspace?.role === WorkspaceUserRoles.OWNER ||
          currentWorkspace?.role === WorkspaceUserRoles.ADMIN) && (
          <NavItem
            label={t("workspace.workspaceOptions")}
            isActive={window.location.pathname.includes("/workspace")}
            Icon={MdOutlineSettings}
            onClick={() => {
              if (isMobile) setIsSidebarOpen(false);
              navigate("/workspace");
            }}
          />
        )}
      {currentWorkspace &&
        currentWorkspace?.role &&
        (currentWorkspace?.role === WorkspaceUserRoles.OWNER ||
          currentWorkspace?.role === WorkspaceUserRoles.ADMIN ||
          currentWorkspace?.privileges?.canRemoveUsersFromWorkspace ||
          currentWorkspace?.privileges?.canAddUsersToWorkspace) && (
          <NavItem
            label={t("users.users")}
            isActive={window.location.pathname.includes("/users")}
            Icon={PiUsersThreeBold}
            onClick={() => {
              if (isMobile) setIsSidebarOpen(false);
              navigate("/users");
            }}
          />
        )}
      <NavItem
        label={t("notifications.title")}
        isActive={window.location.pathname.includes("/notifications")}
        Icon={MdNotifications}
        onClick={() => {
          if (isMobile) setIsSidebarOpen(false);
          navigate("/notifications");
        }}
      />
    </Flex>
  );
};

export default SidebarItems;
