import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import SectionHeading from "../../Molecules/Common/SectionHeading";
import SectionItem from "../../Molecules/Common/SectionItem";
import Select, { Option } from "../../Molecules/Select/Select";
import { AppContext } from "../../../context/AppContext";
import { useGetUsersToAssign } from "../../../hooks/jobs";

interface AssignedToProps {
  selectedUserOption: Option | Option[] | undefined;
  onChangeUsers: (value: Option | Option[] | undefined) => void;
  workspaceId?: number;
}

const AssignedTo = ({
  selectedUserOption,
  workspaceId,
  onChangeUsers,
}: AssignedToProps) => {
  const { t } = useTranslation();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const { currentWorkspace } = useContext(AppContext);
  const [assignedUsersFilter, setAssignedUsersFilter] = useState<
    string | undefined
  >(undefined);

  const { users } = useGetUsersToAssign({
    workspaceId: workspaceId ? workspaceId : currentWorkspace?.id,
    filter: assignedUsersFilter,
  });

  const [usersOptions, setUserOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (users?.length) {
      const options = users.map((user) => {
        const option: Option = {
          value: user.id?.toString(),
          label: user.firstName + " " + user.lastName,
          src: user.filePath,
          subtitle: user.email,
        };
        return option;
      });
      setUserOptions(options);
    } else {
      setUserOptions([]);
    }
  }, [users]);

  return (
    <>
      <SectionHeading
        title={t("job.assignedToTitle")}
        subtitle={t("job.assignedToSubtitle")}
        sx={{ my: "40px" }}
      />
      <SectionItem
        label={""}
        sx={{
          flexDir: ["column", "column", "row"],
          gap: "20px",
        }}
        labelColor="gray.darker"
      >
        <Select
          isMulti={true}
          isSearchable={true}
          name="userTypes"
          theme="withSuggestions"
          options={usersOptions}
          withImages={true}
          defaultOption={selectedUserOption}
          placeholder={t("job.jobNotAssigned")}
          onChange={(options) => {
            if (timeoutId) {
              clearTimeout(timeoutId);
              setTimeoutId(null);
            }
            if (options && Array.isArray(options)) {
              onChangeUsers(options);
            }
          }}
          onInputChange={(value) => {
            if (timeoutId) {
              clearTimeout(timeoutId);
              setTimeoutId(null);
            }
            const id = setTimeout(() => {
              setAssignedUsersFilter(value.toString() || undefined);
            }, 500);
            setTimeoutId(id);
          }}
        />
      </SectionItem>
    </>
  );
};

export default AssignedTo;
